import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { Grid, Typography, Link } from "@material-ui/core";
import { Helmet } from "react-helmet";

import { FzForYouQuery } from "../../graphql-types";
import {
  BenefitItem,
  Footer,
  Header,
  HomeHero,
  ImageWithStand,
  MarkdownDescription,
  useFzForYouStyles,
  useHomeStyles,
} from "../components";
import { toSuperScript } from "../utils";

const anchors = ["live-your-life", "make-change", "feel-supported"];

interface Props {
  data: FzForYouQuery;
}

const FzForYou: React.FC<Props> = ({ data }: Props) => {
  const { strapiFzForYouContent } = data;

  const classes = useFzForYouStyles();
  const homeClasses = useHomeStyles();

  return (
    <div>
      <Helmet title="Flourishzone for You">
        <meta name="description" content={strapiFzForYouContent.subtitle} />
        <meta name="image" content="/fz-for-you-hero.png" />
        <meta property="og:url" content="/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={strapiFzForYouContent.subtitle}
        />
        <meta property="og:image" content="/fz-for-you-hero.png" />
      </Helmet>

      <Header />

      <HomeHero
        img="/fz-for-you-hero.png"
        title={strapiFzForYouContent.title}
        subtitle={strapiFzForYouContent.subtitle}
        actionLabel={strapiFzForYouContent.call_to_action}
      />

      <div className={classes.content}>
        {strapiFzForYouContent.images_with_stand.map((section, index) => (
          <Grid
            className={homeClasses.description_section}
            container
            justify="center"
            id={anchors[index]}
            key={section.id}
          >
            <Grid container item xs={11} md={10} spacing={3}>
              <Grid
                className={clsx(homeClasses.order, section.position)}
                item
                xs={12}
                md={6}
              >
                <ImageWithStand
                  src={section.image[0].url}
                  standColor={section.color}
                  standPosition={section.position === "left" ? "right" : "left"}
                  // @ts-ignore
                  adornment={section.adornment}
                />
              </Grid>
              <Grid
                className={clsx(
                  homeClasses.order,
                  section.position === "left" ? "right" : "left"
                )}
                item
                xs={12}
                md={6}
              >
                <MarkdownDescription
                  accentColor={section.color}
                  description={section.description}
                />
                <Link
                  className={clsx(
                    homeClasses.description_item,
                    homeClasses.description_link
                  )}
                  href="https://portal.flourish.zone/register"
                >
                  Find out more
                </Link>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid
          className={homeClasses.description_section}
          justify="center"
          container
          id="features-and-benefits"
        >
          <Grid item xs={11} md={10}>
            <div className={homeClasses.centered_title}>
              <Typography variant="h4">
                <b>{strapiFzForYouContent.benefits_section_title}</b>
              </Typography>
              <Typography variant="body1">
                {strapiFzForYouContent.benefits_section_subtitle}
              </Typography>
            </div>
            <Grid container spacing={3}>
              {strapiFzForYouContent.benefit_items.map(benefit => (
                <Grid key={benefit.title} item md={3}>
                  <BenefitItem
                    title={benefit.title}
                    description={benefit.description}
                    // @ts-ignore
                    icon={benefit.icon}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className={homeClasses.description_section}
          justify="center"
          container
        >
          <Grid item xs={11} md={10} xl={8}>
            <div className={homeClasses.centered_title}>
              <Typography variant="h4">
                <b
                  dangerouslySetInnerHTML={{
                    __html: toSuperScript(
                      strapiFzForYouContent.app_section_title
                    ),
                  }}
                />
              </Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: toSuperScript(
                    strapiFzForYouContent.app_section_subtitle
                  ),
                }}
              />
              <Typography variant="caption">
                {strapiFzForYouContent.app_section_caption}
              </Typography>
            </div>
            <img
              className={classes.mobile_app_image}
              src={
                strapiFzForYouContent.app_section_image.childImageSharp.original
                  .src
              }
              alt="flourish zone mobile app"
            />
          </Grid>
        </Grid>

        <Grid
          className={homeClasses.description_section}
          justify="center"
          container
          id="download-the-app"
        >
          <Grid item xs={8} sm={6} md={4}>
            <div className={homeClasses.centered_title}>
              <Typography variant="h4">
                <b>{strapiFzForYouContent.download_section_title}</b>
              </Typography>
              <Typography variant="body2">
                {strapiFzForYouContent.download_section_subtitle}
              </Typography>
            </div>
            <div className={classes.download_links}>
              <a
                href="https://play.google.com/store/apps"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src="/google-play-logo.png" alt="google play" />
              </a>
              <a
                href="https://www.apple.com/uk/ios/app-store/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src="/app-store-logo.png" alt="app store" />
              </a>
            </div>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  );
};

export default FzForYou;

export const query = graphql`
  query FzForYou {
    strapiFzForYouContent {
      title
      subtitle
      call_to_action
      images_with_stand {
        id
        position
        color
        adornment
        description
        image {
          url
        }
      }
      benefits_section_title
      benefits_section_subtitle
      benefit_items {
        title
        description
        icon
        id
      }
      app_section_title
      app_section_subtitle
      app_section_caption
      app_section_image {
        childImageSharp {
          original {
            src
          }
        }
      }
      download_section_subtitle
      download_section_title
    }
  }
`;
